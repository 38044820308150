.news {
  .one {
    background: url(../assets/news2x.png) no-repeat center center;

    .context {
      
      .big {
        font-size: 48px;
      }
    }
  }

  .two {
    width: 100%;
    max-width: 1200px;
    margin: 90px auto 36px auto;
    padding: 0 10px;

    >div {
      padding: 28px 0;
      border-bottom: 1px solid #e6e6e6;
      display: flex;

      >img {
        width: 248px;
        height: 165px;

        @media screen and (max-width:800px) {
          width: 112px;
          height: 74px;
        }
      }

      .right {
        flex: 1;
        padding-left: 20px;

        .title {
          font-size: 18px;
          color: rgba(64, 64, 64, 1);
          line-height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .source {
          font-size: 13px;
          color: rgba(150, 150, 150, 1);
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .context {
          font-size: 14px;
          color: rgba(100, 100, 100, 1);
          line-height: 23px;
          margin-top: 5px;
          margin-bottom: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        a {
          display: inline-block;
          border: 1px solid rgba(230, 230, 230, 1);
          background: none;
          color: #848484;
          line-height: 30px;
          padding: 0 22px;
          text-decoration: none;

          &:hover {
            background-color: #00A4F9;
            color: #fff;
          }
        }
      }
    }
  }

  .pages {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 100px auto;
    text-align: center;

    span {
      float: left;
    }

    button {
      background: rgba(0, 164, 249, 1);
      border-radius: 2px 2px 2px 2px;
      line-height: 36px;
      border: none;
      color: #fff;
      padding: 0 13px;

      &:focus {
        outline: none;
      }
    }
  }
}