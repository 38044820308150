.case {
  .one {
    background: url(../assets/casebg.png) no-repeat center center;
    height: 980px;

    @media screen and (max-width:900px) {
      height: 680px;
    }

    @media screen and (max-width:700px) {
      height: 580px;
    }

    @media screen and (max-width:500px) {
      height: 480px;
    }

    .context {
      animation: fadeIn;
      animation-duration: 1s;
      margin-bottom: -50px;

      .big {
        font-size: 48px;
      }

      .gdmap {
        text-align: center;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .two {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    max-width: 100%;
    margin: 107px auto;
    outline: 0.5px solid #f0f0f0;

    >div {
      width: 33.3333%;
      height: 260px;
      outline: 0.5px solid #f0f0f0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width:800px) {
        width: 50%;
      }

      @media screen and (max-width:600px) {
        width: 100%;
      }

      img {
        height: 130px;
      }

      span {
        display: block;
        margin-top: 30px;
      }
    }
  }

  .three {
    text-align: center;
    padding: 75px 10px;

    img {
      width: 769px;
      max-width: 100%;
    }
  }
}