.scheme {
  .one {
    background: url(../assets/scheme2x.png) no-repeat center center;

    .context {

      .big {
        font-size: 48px;
      }
    }
  }

  .two {
    text-align: center;
    margin-top: 83px;
    margin-bottom: 116px;
    padding: 0 15px;

    >div {
      font-size: 18px;
      line-height: 40px;

      span {
        color: rgba(31, 163, 255, 1);
      }
    }

    img {
      width: 1198px;
      max-width: 99%;
      margin-top: 98px;
    }
  }

  .three {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 80px 10px;
    background-color: #F7F8FA;

    >.title {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      margin: 59px 0;
    }

    .gird {
      display: grid;
      grid-template-columns: 420px 320px 420px;
      grid-template-rows: repeat(2, 183px);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      @media screen and (max-width:1200px) {
        display: block;

        >div {
          margin-bottom: 20px;
          height: auto;
        }

        .mid {
          display: flex !important;

          img {
            width: 190px !important;
          }
        }
      }

      >div {
        background-color: #fff;
        display: flex;

        &:hover {
          transition: all 0.3s;
          transform: scale(1.05, 1.05);
        }

        img {
          width: 190px;
          height: 183px;
        }

        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {
            display: block;
            font-size: 14px;
            padding: 0 24px;
          }

          .tt {
            font-size: 24px;
            margin-bottom: 15px;
          }
        }
      }

      .mid {
        display: block;
        grid-area: 1 / 2 / 3 / 3;

        img {
          width: 100%;
        }

        div {
          min-height: 183px;
        }
      }
    }

    .ieGird {
      display: flex;
      flex-wrap: wrap;
      width: 1200px;
      max-width: 100%;

      >div {
        width: 580px;
        margin: 10px;

        @media screen and (max-width:1200px) {
          width: 100%;
        }
      }

      .mid {
        display: flex;

        img {
          width: 190px;
          height: 183px;
        }
      }
    }
  }

  .four {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 110px;

    >.title {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      margin: 59px 0;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1200px;

      >div {
        width: 580px;
        max-width: calc(50% - 20px);
        min-height: 226px;
        background-color: #fff;
        margin: 10px;
        border: 2px solid rgba(229, 229, 229, 1);
        box-sizing: border-box;
        padding: 26px 33px;

        @media screen and (max-width:1190px) {
          width: 100%;
          max-width: 100%;
        }

        .title {
          font-size: 16px;
          color: #343434;
          margin-bottom: 25px;
          display: flex;
          align-items: center;

          img {
            width: 50px;
            vertical-align: top;
            margin-left: 10px;
            margin-right: 25px;
          }

          span {
            font-size: 18px;
            color: #00A4FF;
            display: block;
            margin-bottom: 7px;
          }
        }

        .context {
          color: #666666;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }
}