.contact {
  .one {
    background: url(../assets/contact2x.png) no-repeat center center;
  }

  .two {

    .context {
      text-align: center;
      background-color: #ffffff;
      width: 1200px;
      max-width: 100%;
      margin: 0 auto 80px auto;
      position: relative;
      top: -160px;
      border-radius: 4px;
      box-shadow: 0px 0px 38px 0px rgba(191, 190, 187, 0.26);

      @media screen and (max-width:1200px) {
        top: 0;
      }

      .title {
        font-size: 48px;
        color: #333333;
        line-height: 160px;
        font-style: italic;
        font-weight: 600;
      }

      p {
        padding: 0 63px;
        line-height: 48px;
        font-size: 20px;  
        color: #333333;
        margin: 0;

        @media screen and (max-width:1200px) {
          padding: 0 10px;
          font-size: 18px;  

          .number {
            font-size: 32px !important;
          }
        }

        .number a{
          font-size: 36px;
          font-weight: bold;
          color: #3DBAFF;
          text-decoration: none;
        }

        .time {
          font-size: 20px;
          color: rgba(51, 51, 51, 1);
          opacity: 0.67;
        }
      }

      .map{        
        width: 1002px;
        height: 390px;
        margin: -50px auto 0 auto;
        max-width: 100%;
        position: relative;
        top: 100px;
        border: 1px solid #8fd0f3;
      }
    }
  }
}