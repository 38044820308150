.home {
  .one {
    background: url(../assets/homebg2x.png) no-repeat center center;
  }
}

.newapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F7F8FA;
  padding-bottom: 150px;

  >.title {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    margin: 59px 0;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;

    >div {
      width: 380px;
      height: 176px;
      background-color: #fff;
      margin: 8px 10px;
      box-sizing: border-box;
      padding: 26px 33px;

      &:hover {
        transition: all 0.3s;
        transform: scale(1.05, 1.05);
      }

      @media screen and (max-width:1200px) {
        width: calc(50% - 20px);
      }

      @media screen and (max-width:750px) {
        width: 100%;
      }

      .title {
        font-size: 18px;
        color: #343434;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        img {
          width: 28px;
          vertical-align: top;
          margin-right: 13px;
        }
      }

      .context {
        color: #666666;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}