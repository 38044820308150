body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$ffWidth: 1200px;

.header {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;

  .navbar {
    width: $ffWidth;
    height: 90px;
    padding: 0;

    .navbar-brand {
      margin-right: 0;
      padding: 29px 0;
      height: 90px;

      @media screen and (max-width:990px) {
        margin-left: 10px;
      }

      .logo {
        width: 265px;
        height: 37px;

        @media screen and (max-width:990px) {
          width: 200px;
          height: 28px;
        }
      }
    }

    .navbar-nav {
      @media screen and (max-width:990px) {
        background-color: #ffffff;
        box-shadow: 0 2px 15px #eee;

        .nav-link {
          color: #000000 !important;
          line-height: 50px;

          &:hover {
            background-color: #20A4FF;
            color: #fff !important;
          }
        }

        .active {
          background: none;
        }
      }
    }

    .nav-link {
      color: #ffffff !important;
      padding: 0 20.5px !important;
      line-height: 90px;
      text-align: center;

      &:hover {
        background: linear-gradient(180deg, rgba(24, 130, 255, 1), rgba(17, 63, 183, 0));
      }
    }

    .active {
      background: linear-gradient(180deg, rgba(24, 130, 255, 1), rgba(17, 63, 183, 0));
    }

    .nav-link:first-child {
      background: none;
    }

    .navbar-toggler {
      border-color: rgba(256, 256, 256, 0.8);
      margin-right: 10px;
      color: rgba(256, 256, 256, 0.8);

      &:focus {
        outline: none;
      }
    }

    // 这里会导致build失败，暂时注释用App.css代替
    // .navbar-toggler-icon {
    //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28256, 256, 256, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    // }
  }

  .justify-content-end {
    width: 100%;
  }

  .navbar-collapse {
    z-index: 1;
  }
}

.footer {
  // 这里会导致build失败，暂时注释用App.css代替
  // background: url(./assets/footerbg2x.png) no-repeat center center;
  // background-size: cover;
  padding: 60px 10px;

  .content {
    color: #ffffff;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    display: flex;

    @media screen and (max-width:1100px) {
      display: block;

      .left {
        width: 100%;
        margin-bottom: 60px;
      }
    }

    .left {
      flex: 1;
      margin-right: 70px;

      .title {
        font-size: 18px;
        margin-bottom: 27px;
      }

      .context {
        font-size: 13px;
        line-height: 23px;
      }
    }

    .right {
      flex: 1;

      .top {
        display: flex;

        .me {
          flex: 1;

          .title {
            font-size: 18px;
            margin-bottom: 27px;
          }

          .context {
            font-size: 13px;
            line-height: 33px;

            a {
              color: #3DBAFF;
              text-decoration: none;
              font-size: 22px;
            }
          }
        }

        .code {
          width: 150px;
          text-align: center;

          @media screen and (max-width:520px) {
            width: 150px !important;
          }

          p {
            margin-bottom: 35px;
          }

          img {
            width: 125px;
            height: 125px;
          }
        }
      }

      .keep {
        font-size: 12px;
        margin-top: 32px;

        img {
          width: 16px;
          height: 18px;
          vertical-align: top;
        }

        a {
          color: #ffffff;
        }
      }
    }
  }
}

.product {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  .title {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    margin: 59px 0;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;

    >div {
      width: 300px;
      height: 110px;
      margin-bottom: 70px;

      @media screen and (max-width:$ffWidth) {
        width: 33.33%;
      }

      @media screen and (max-width:700px) {
        width: 50%;
      }

      @media screen and (max-width:500px) {
        width: 100%;
      }

      img {
        height: 55px;
      }

      span {
        display: block;
        margin-top: 30px;
      }
    }
  }
}

.one {
  height: 598px;
  background-size: cover !important;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .context {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    text-shadow: 0px 2px 12px rgba(24, 45, 247, 0.31);
    animation: fadeIn;
    animation-duration: 1s;

    .big {
      font-size: 62px;
      font-style: italic;
      line-height: 110px;
      font-weight: 600;
      letter-spacing: 5px;
    }

    .sm {
      max-width: 723px;
      font-size: 18px;
      line-height: 32px;
    }
  }

  @media screen and (max-width:990px) {
    height: 300px;

    .context {
      margin-top: 50px;

      .big {
        font-size: 32px !important;
        line-height: 48px !important;
        letter-spacing: 1px !important;
      }

      .sm {
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }
  }
}