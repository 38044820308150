.center {
  .one {
    background: url(../assets/center2x.png) no-repeat center center;

    .context {
      text-shadow: 0px 2px 12px #0c6ae0;

      .big {
        font-size: 46px;
        line-height: 100px;
        letter-spacing: 4px;
      }

      .sm {
        max-width: 720px;
        font-size: 17px;
      }
    }
  }

  .three {
    text-align: center;
    color: #fff;
    background: url(../assets/yjbg2x.png) no-repeat center center;
    background-size: cover;
    padding: 68px 10px 106px 10px;

    .title {
      font-size: 32px;
      font-weight: bold;
    }

    .context {
      line-height: 27px;
      width: 100%;
      max-width: 690px;
      margin: 80px auto 94px auto;
    }

    img {
      width: 911px;
      max-width: 100%;
    }
  }

  .four {
    text-align: center;
    padding-top: 84px;
    padding-bottom: 106px;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;

    .title {
      font-size: 32px;
      font-weight: bold;
    }

    .tabs {
      margin-top: 74px;
      margin-bottom: 54px;
      display: flex;
      justify-content: center;

      @media screen and (max-width:800px) {
        display: block;

        >div {
          width: 100% !important;
        }
      }

      >div {
        width: 238px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          color: #00A3F9;
        }
      }

      .action {
        background-image: linear-gradient(#e1f4ff 50%, #fff);
        color: #00A3F9;
      }
    }

    .change {
      display: flex;

      .left {
        background: url(../assets/fleft2x.png) no-repeat center;
        background-size: 24px 54px;
        
        &:hover {
          background: url(../assets/left_active.png) no-repeat center;
          background-size: 24px 54px;
        }
      }

      .right {
        background: url(../assets/fright2x.png) no-repeat center;
        background-size: 24px 54px;

        &:hover {
          background: url(../assets/right_active.png) no-repeat center;
          background-size: 24px 54px;
        }
      }

      .left,
      .right {
        flex: 1;
        cursor: pointer;
        margin: 0 5px;
      }

      .mid {
        flex: 8;
        border: 1px solid rgba(219, 219, 219, 1);
        border-radius: 4px;
        text-align: left;
        display: flex;

        @media screen and (max-width:800px) {
          flex-direction: column;
        }

        img {
          width: 402px;
          max-width: 100%;
          max-height: 333px;
        }

        >div {
          flex: 1;
          padding: 0 30px;
          box-sizing: border-box;

          span {
            font-size: 22px;
            color: #333;
            display: block;
            margin-top: 48px;
            margin-bottom: 40px;
          }

          p {
            font-size: 14px;
            color: #777;
            line-height: 25px;
          }
        }
      }
    }
  }
}