.introduce {
  .one {
    background: url(../assets/introduce2x.png) no-repeat center center;

    .context {

      .big {
        font-size: 48px;
      }
    }
  }

  .two {

    .context {
      background-color: #ffffff;
      width: 1200px;
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      top: -110px;
      border-radius: 4px;

      @media screen and (max-width:1200px) {
        top: 0;
        padding-bottom: 50px;
      }

      .title {
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #333333;
        line-height: 160px;
      }

      p {
        padding: 0 63px;
        line-height: 36px;
        font-size: 18px;
        color: #333333;

        @media screen and (max-width:600px) {
          padding: 0 15px;
          line-height: 34px;
        }
      }
    }

    .intr {
      width: 1920px;
      height: 517px;
      max-width: 100%;
      background: url(../assets/vision2x.png) no-repeat center;
      background-size: cover;
      margin: -220px auto 0 auto;
      text-align: center;
      box-sizing: border-box;
      padding-top: 220px;

      @media screen and (max-width:1200px) {
        padding-top: 270px;
      }

      .title {
        font-size: 32px;
        font-weight: bold;
        color: rgba(0, 164, 249, 1);
      }

      .sub {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        line-height: 51px;

        img {
          width: 57px;
          margin: 0 30px 66px 30px;
        }
      }

      @media screen and (max-width:990px) {
        .sub {
          font-size: 18px;
          color: rgba(51, 51, 51, 1);
          line-height: 30px;
          padding-top: 50px;

          img {
            width: 30px;
            margin: 0 20px 30px 20px;
          }
        }
      }
    }
  }
}